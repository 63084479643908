<template>
  <div class="container-md">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <form @submit="submitHandler" novalidate autocomplete="off" class="form">
          <div class="mb-3">
            <div class="alert alert-success">
              <span>{{ $t('pinWasSent') }}</span>
            </div>
          </div>
          <div class="mb-3" v-if="error">
            <div class="alert alert-danger">
              <span>{{ error }}</span>
            </div>
          </div>
          <div class="mb-3">
            <label for="pin" class="form-label">{{ $t('pin') }}</label>
            <input type="text" v-maska="'######'" placeholder="######" v-model="form.pin"
                   pattern="[0-9]*" inputmode="numeric"
                   :class="'form-control text-center' + (v$.form.pin.$errors.length > 0 ? ' is-invalid' : '')"
                   aria-describedby="pinMessage"
                   id="pin">
            <div v-if="v$.form.pin.$errors.length > 0" id="pinMessage" class="invalid-feedback">
              {{ getValidationErrorText(v$.form.pin.$errors[0].$validator) }}
            </div>
          </div>
          <div class="mb-3 text-center">
            <a :href="this.$testReLogin" v-if="backButton" class="btn mr-1">
              <img src="/images/back.svg" class="question" alt=""/>
            </a>
            <button type="submit" class="btn btn-success">{{ $t('continue') }}</button>
          </div>
        </form>
        <Loader :state="loading"></Loader>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from './Loader.vue'
import useVuelidate from '@vuelidate/core'
import {required, minLength} from '@vuelidate/validators'

export default {
  components: {
    Loader,
  },
  inject    : ['$api_base_path', '$testReLogin'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      loading      : true,
      backButton   : false,
      error        : null,
      form         : {
        pin: null
      },
      formValidated: false,
    }
  },
  validations() {
    return {
      form: {
        pin: {
          required,
          min: minLength(6)
        }
      }
    }
  },
  mounted() {
    this.loading = false;
    this.smsOTPCredential();
  },
  methods: {
    getValidationErrorText: function (v) {
      return this.$t('validation_' + v);
    },
    submitHandler         : function (e) {
      e.preventDefault()
      const _self = this;
      _self.v$.$validate() // checks all inputs
      _self.formValidated = true;
      if (_self.v$.$error) { // if ANY fail validation
        return;
      }
      const payload = {
        ref_id: _self.$store.getters.refId,
        pin   : _self.form.pin,
      };
      _self.loading = true
      _self.error   = null
      _self.axios.post(_self.$api_base_path + "/api/approve", payload, {
        headers: {
          'Accept'         : 'application/json',
          'Content-Type'   : 'application/json',
          'Accept-Language': _self.$i18n.locale
        }
      }).then((response) => {
        if (response.data.success) {
          _self.loading = false
          _self.$emit('approveComplete')
        } else {
          _self.error      = response.data.error
          _self.loading    = false
          _self.backButton = true
        }
      })
    },
    smsOTPCredential() {
      const ac = new AbortController();
      navigator.credentials.get({
        otp   : {transport: ['sms']},
        signal: ac.signal
      }).then(otp => {
        this.form.pin = otp.code;
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>