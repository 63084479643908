<template>
  <div class="container-md">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <div class="result-wrapper">
          <div v-if="error">
            <div class="mb-3">
              <img src="/close.png" alt="">
            </div>
            <div class="mb-3">
              <span>{{ error }}</span>
            </div>
            <a :href="this.$testReLogin" class="btn btn-outline-success btn-lg">{{ $t('goBack') }}</a>
          </div>
          <div v-if="success">
            <div class="mb-3">
              <img src="/checked.png" alt="">
            </div>
            <div class="mb-3">
              <span>{{ $t('lets_continue') }}</span>
            </div>
          </div>
        </div>
        <Loader :state="loading"></Loader>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader";

export default {
  components: {Loader},
  inject    : ['$api_base_path', '$testReLogin'],
  data() {
    return {
      loading: true,
      error  : null,
      success: false
    };
  },
  mounted() {
    const _self   = this;
    const payload = {
      ref_id: _self.$store.getters.refId,
      image : _self.$store.getters.image,
    }
    _self.axios.post(_self.$api_base_path + "/api/auth", payload, {
      headers: {
        'Accept'         : 'application/json',
        'Content-Type'   : 'application/json',
        'Accept-Language': _self.$i18n.locale
      }
    }).then((response) => {
      if (response.data.success) {
        setTimeout(() => _self.checkAttemptResult(1), 3000)
        // sessionStorage.removeItem('auth_error')
      } else {
        _self.error   = response.data.error
        _self.loading = false

        // if (response.data.code === -2000) {
        //   sessionStorage.setItem('auth_error', 'detection_failed')
        //   _self.$emit('authError')
        // }
      }
    })
  },
  methods: {
    checkAttemptResult: function (count) {
      const _self = this;
      _self.axios.get(_self.$api_base_path + "/api/result?ref_id=" + _self.$store.getters.refId, {
        headers: {
          'Accept'         : 'application/json',
          'Accept-Language': _self.$i18n.locale
        }
      }).then((response) => {
        if (response.data.success) {
          const state = response.data.data.state;
          // 1 - pending, 2 - complete
          if (state === 1) {
            if (count >= 10) {
              _self.error   = "Истекло время ожидания. Попробуйте заново!"
              _self.loading = false
            } else {
              setTimeout(() => _self.checkAttemptResult(count + 1), 3000)
            }
          } else {
            _self.success = true
            _self.loading = false
            setTimeout(() => window.location.href = response.data.data.redirect_uri, 1000)
          }
        } else {
          _self.error   = response.data.error
          _self.loading = false
        }
      })
    }
  }
}
</script>