<template>
  <main>
    <div class="logo-wrapper">
      <div class="mt-3 mb-3">
        <div class="container-md">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 text-center">
              <img src="/images/intend.png" class="logo img-fluid" alt="inTend"/>
              <!--<img src="/my_id_logo.png" class="logo img-fluid" alt="inTend"/>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-wrapper">
      <Passport @passportComplete="passportCompleteHandler" v-if="step === 'passport'"/>
      <Approve @approveComplete="approveCompleteHandler" v-if="step === 'approve'"/>
      <Detector @detectionComplete="detectionCompleteHandler" v-if="step === 'detector'"/>
      <DetectionResult v-if="step === 'result'"></DetectionResult>
      <div v-if="step === 'none'">
        <div class="container-md mt-5">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 text-center">
              <a :href="this.$testReLogin" class="btn btn-outline-success btn-lg">
<!--                <img src="/images/back.svg" class="question" alt=""/>-->
                {{ $t('goBack') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--    <div>
            <p v-for="c in cameras">{{ c.label }}</p>
          </div>-->
    </div>
  </main>
</template>
<script>
import Detector from './components/Detector.vue'
import Passport from './components/Passport.vue'
import Approve from './components/Approve.vue'
import DetectionResult from "@/components/DetectionResult";

export default {
  components: {
    DetectionResult,
    Detector,
    Passport,
    Approve
  },
  inject    : ['$api_base_path', '$testReLogin'],
  data() {
    return {
      step        : 'none',
      device      : null,
      deviceStatus: 0,
      cameras     : [],
    }
  },
  mounted() {
    const _self     = this;
    const urlParams = new URLSearchParams(window.location.search);
    const ref_id    = urlParams.get("ref_id")
    const locale    = urlParams.get("l")
    if (ref_id) {
      _self.step = 'passport'
      _self.$store.commit('setRefId', ref_id)
      _self.$store.commit('setLocale', locale)
      _self.$i18n.locale = locale
      _self.$router.push('/')
    }
  },
  methods: {
    passportCompleteHandler : function () {
      this.step = 'approve'
    },
    approveCompleteHandler  : function () {
      this.step = 'detector'
    },
    detectionCompleteHandler: function () {
      const _self = this;
      setTimeout(() => {
        _self.step = 'result'
      }, 1200)
    },
  }
}
</script>
<style>
@import './assets/base.css';
</style>