<template>
  <div :class="state ? 'loader' : 'loader hidden' ">
    <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['state'],
}
</script>