import {createStore} from 'vuex'
//import {inject} from 'vue'

const store = createStore({
    state() {
        return {
            refId: null,
            passport: null,
            birthDate: null,
            phone: null,
            agreement: false,
            image: null,
            locale: 'uz',
        }
    },
    getters: {
        refId(state) {
            return state.refId
        },
        image(state) {
            return state.image
        },
        locale(state) {
            return state.locale
        }
    },
    mutations: {
        setRefId(state, refId) {
            state.refId = refId
        },
        setForm(state, form) {
            state.passport = form.passport
            state.birthDate = form.birthDate
            state.phone = form.phone
            state.agreement = form.agreement
        },
        setImage(state, image) {
            state.image = image
        },
        setLocale(state, locale) {
            state.locale = locale
        },
    },
    actions: {
        /*createAttempt() {
            const axios = inject('axios')
            const api_base_path = inject('$api_base_path')
            const _self = this;
            axios.get(api_base_path + "/api/attempt").then((response) => {
                _self.state.refId = response.data.data.ref_id
                //console.log(response.data.data.ref_id)
            })
        }*/
    }
})

export default store