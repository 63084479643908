import {createI18n} from 'vue-i18n'
import ru from '../locales/ru.json'
import uz from '../locales/uz.json'

export const i18n = createI18n({
    locale: 'uz',
    fallbackLocale: 'uz',
    messages: {
        uz, ru
    },
})